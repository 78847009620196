import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import LeafLogo from "../img/leaf-logo.png"
import RadioButton from "./body/RadioButton";
import OtherGuests from "./body/OtherGuests";
import { useLocation, useNavigate } from "react-router-dom";
import './Form.css';

const Form = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state.fromPage;

  const refCaptcha = useRef();
  // ⭐️⭐️⭐️がコメントに付いている変数は、送信ボタンで送信する対象
  const [firstName, setFirstName] = useState(""); // 名（漢字） ⭐️⭐️⭐️
  const [lastName, setLastName] = useState(""); // 姓（漢字） ⭐️⭐️⭐️
  const [firstNameKana, setFirstNameKana] = useState(""); // 名（かな） ⭐️⭐️⭐️
  const [lastNameKana, setLastNameKana] = useState(""); // 姓（かな） ⭐️⭐️⭐️
  const [address1, setAddress1] = useState(""); // 住所１ ⭐️⭐️⭐️
  const [address2, setAddress2] = useState(""); // 住所２ ⭐️⭐️⭐️
  const [email, setEmail] = useState(""); // メールアドレス ⭐️⭐️⭐️
  const [tel, setTel] = useState(""); // 電話番号 ⭐️⭐️⭐️
  const [telValidAlert, setTelValidAlert] = useState(false)
  // 全角数字を半角数字に変換する処理
  const convertFullWidthToHalfWidth = (input) => {
    return input.replace(/[０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}
  // 電話番号バリデーション
  const handleChangeTel = (e) => {
    const inputTel = convertFullWidthToHalfWidth(e.target.value);
    const regex = /^[0-9]*$/;
    if (regex.test(inputTel)) {
      setTel(inputTel);
      setTelValidAlert(false);
    }else{
      setTelValidAlert(true);
    }
  }
  const [message, setMessage] = useState(""); // メッセージ ⭐️⭐️⭐️
  const [allergy, setAllergy] = useState(""); // アレルギー欄 ⭐️⭐️⭐️
  const [otherGuests, setOtherGuests] = useState([
    // {
    //   id: "",
    //   lastName: "",
    //   firstName: "",
    //   lastNameKana: "",
    //   firstNameKana: "",
    //   message: ""
    // }
]); // お連れ様（配列） ⭐️⭐️⭐️
  const handleOtherGuests = (otherGuests) => {
    setOtherGuests(otherGuests)
  }
  // お連れ様を追加する処理
  const AddOtherGuest = () => {
    if(otherGuests.length === 5){
      return;
    }
    if(selectedRadioBtnAttendance === "ご出席"){
      const newOtherGuest = {
        guestLastName: "",
        guestFirstName: "",
        guestLastNameKana: "",
        guestFirstNameKana: "",
        guestMessage: ""
      }
      if(otherGuests.length === 0){
        setOtherGuests([newOtherGuest]);
      }else{
        setOtherGuests([...otherGuests, newOtherGuest])
      }
    }else{
      return;
    }
  }





  function onChangeReCaptcha(value){
      // console.log("Captcha value 2 :", value);
  }
  function onErrorReCaptcha(value){
      console.log("RecaptchaError:", value);
  }


  const handleToConfirm = (e) => {
    e.preventDefault(); // デフォルトの送信動作を防止
    // navigate('./confirm');
    navigate('./confirm',
      { state:
        {
          firstName,
          lastName,
          firstNameKana,
          lastNameKana,
          address1,
          address2,
          email,
          tel,
          message,
          allergy,
          otherGuests,
          selectedRadioBtnAttendance,
          selectedRadioBtnGuest
        }
      }
    ); // 確認画面に遷移し、form入力情報を渡す

    // パラメータとして渡すtokenを取得
    // const token = refCaptcha.current.getValue();
    // // emailjsのUser_IDを使って初期化
    // emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_ID);
    // // 環境変数からService_IDとTemplate_IDを取得する。
    // const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    // const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    // // emailjsのテンプレートに渡すパラメータを宣言
    // const templateParams = {
    //     name: fromName,
    //     mail: mail,
    //     tel: tel,
    //     message: message,
    //     'g-recaptcha-response': token,
    // };
  }




  // 出欠確認ラジオボタン ⭐️⭐️⭐️
  const [selectedRadioBtnAttendance, setSelectedRadioBtnAttendance] = useState("");
  const handleRadioBtnAttendance = (e) => {
    setSelectedRadioBtnAttendance(e.target.value);
  }
  const radioButtonsAttendance = [
    {
      eng: "ACCEPTS",
      jpn: "ご出席"
    },{
      eng: "DECLINES",
      jpn: "ご欠席"
    }
  ];
  // 新郎新婦どちらのゲストかラジオボタンの状態変数 ⭐️⭐️⭐️
  const [selectedRadioBtnGuest, setSelectedRadioBtnGuest] = useState("");
  const handleRadioBtnGuest = (e) => {
    setSelectedRadioBtnGuest(e.target.value);
  }
  const radioButtonsGuest = [{
    eng: "Groom's guest",
    jpn: "新郎ゲスト"
  },{
    eng: "Bride's guest",
    jpn: "新婦ゲスト"
  }];

  // 入力欄の状態変数
  const [isFocusedFirstName, setIsFocusedFirstName] = useState(false);
  const [isFocusedLastName, setIsFocusedLastName] = useState(false);
  const [isFocusedFirstNameKana, setIsFocusedFirstNameKana] = useState(false);
  const [isFocusedLastNameKana, setIsFocusedLastNameKana] = useState(false);
  const [isFocusedAddress1, setIsFocusedAddress1] = useState(false);
  const [isFocusedAddress2, setIsFocusedAddress2] = useState(false);
  const [isFocusedEmail, setIsFocusedEmail] = useState(false);
  const [isFocusedTel, setIsFocusedTel] = useState(false);
  const [isFocusedMessage, setIsFocusedMessage] = useState(false);
  const [isFocusedAllergy, setIsFocusedAllergy] = useState(false);
  // 入力欄での入力時・離脱時の処理
  const handleFocus = (name) => {
    switch(name){
      case 'lastName':
        setIsFocusedLastName(true);
        break;
      case 'firstName':
        setIsFocusedFirstName(true);
        break;
      case 'firstNameKana':
        setIsFocusedFirstNameKana(true);
        break;
      case 'lastNameKana':
        setIsFocusedLastNameKana(true);
        break;
      case 'address1':
        setIsFocusedAddress1(true);
        break;
      case 'address2':
        setIsFocusedAddress2(true);
        break;
      case 'email':
        setIsFocusedEmail(true);
        break;
      case 'tel':
        setIsFocusedTel(true);
        break;
      case 'message':
        setIsFocusedMessage(true);
        break;
      case 'allergy':
        setIsFocusedAllergy(true);
        break;
      default:
        return;
    }
  }
  const handleBlur = (name) => {
    switch(name){
      case 'lastName':
        setIsFocusedLastName(false);
        break;
      case 'firstName':
        setIsFocusedFirstName(false);
        break;
      case 'firstNameKana':
        setIsFocusedFirstNameKana(false);
        break;
      case 'lastNameKana':
        setIsFocusedLastNameKana(false);
        break;
      case 'address1':
        setIsFocusedAddress1(false);
        break;
      case 'address2':
        setIsFocusedAddress2(false);
        break;
      case 'email':
        setIsFocusedEmail(false);
        break;
      case 'tel':
        setIsFocusedTel(false);
        break;
      case 'message':
        setIsFocusedMessage(false);
        break;
      case 'allergy':
        setIsFocusedAllergy(false);
        break;
      default:
        return;
    }
  }



  useEffect(() => {
    // 遷移時に画面上部へスクロール
    window.scrollTo(0, 0);

    // 確認画面から遷移した場合、入力済み情報をセットする
    console.log("fromPage : ", fromPage);
    if(location.state && fromPage === "confirm"){
      setFirstName(location.state.firstName);
      setLastName(location.state.lastName);
      setFirstNameKana(location.state.firstNameKana);
      setLastNameKana(location.state.lastNameKana);
      setAddress1(location.state.address1);
      setAddress2(location.state.address2);
      setEmail(location.state.email);
      setTel(location.state.tel);
      setMessage(location.state.message);
      setAllergy(location.state.allergy);
      setOtherGuests(location.state.otherGuests);
      setSelectedRadioBtnAttendance(location.state.selectedRadioBtnAttendance);
      setSelectedRadioBtnGuest(location.state.selectedRadioBtnGuest);
      console.log("画面遷移時に再度セットした！！！");
    } // eslint-disable-next-line
  }, []);


  return (
    <>
    {/* <div id={isOverlay}>
      <SendCompleteModal isOpen={isOpen} handleToTop={handleToTop} />
    </div> */}
    <div className="form-body">
      <div className="form-card">
        <h1>Invitation</h1>
        <img src={LeafLogo} alt="Leaf-logo" className="form-leaf-logo" />
        <p className="form-annotation">
            <span>※</span>の項目は必須項目です
        </p>
        <form className="form-questions" onSubmit={handleToConfirm}>

            <div className="form-question">
              <p>どちらかをお選びください<span> ※</span></p>
              <RadioButton
                selectedRadioBtn={selectedRadioBtnAttendance}
                handleRadioBtn={handleRadioBtnAttendance}
                radioButtons={radioButtonsAttendance}
              />
            </div>

            <div className="form-question">
              <span>Name</span>
              <p>お名前<span> ※</span></p>
              <div className="input-names">
                <input
                  className={`input-name${isFocusedLastName ? '-active' : ''}`}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="lastName"
                  placeholder="姓"
                  required={true}
                />
                <input
                  className={`input-name${isFocusedFirstName ? '-active' : ''}`}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="firstName"
                  placeholder="名"
                  required={true}
                />
              </div>
            </div>

            <div className="form-question">
              <span>Name(Kana)</span>
              <p>お名前(ふりがな)<span> ※</span></p>
              <div className="input-names">
                <input
                  className={`input-name${isFocusedLastNameKana ? '-active' : ''}`}
                  value={lastNameKana}
                  onChange={(e) => setLastNameKana(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="lastNameKana"
                  placeholder="やまだ"
                  required={true}
                />
                <input
                  className={`input-name${isFocusedFirstNameKana ? '-active' : ''}`}
                  value={firstNameKana}
                  onChange={(e) => setFirstNameKana(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="firstNameKana"
                  placeholder="たろう"
                  required={true}
                />
              </div>
            </div>

            <div className="form-question">
              <p>どちらかをお選びください<span> ※</span></p>
              <RadioButton
                selectedRadioBtn={selectedRadioBtnGuest}
                handleRadioBtn={handleRadioBtnGuest}
                radioButtons={radioButtonsGuest}
              />
            </div>



            <div className="form-question">
              <span>Address</span>
              <p>ご住所</p>
              <div className="input-addresses">
                <input
                  className={`input-address${isFocusedAddress1 ? '-active' : ''}`}
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="address1"
                  placeholder="大阪府⚪︎⚪︎市⚪︎⚪︎区⚪︎⚪︎⚪1−1−1"
                />
                <input
                  className={`input-address${isFocusedAddress2 ? '-active' : ''}`}
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="address2"
                  placeholder="⚪︎⚪︎マンション 101号室"
                />
              </div>
            </div>

            <div className="form-question">
              <span>Email</span>
              <p>メールアドレス<span> ※</span></p>
              <div className="input-emails">
                <input
                  className={`input-email${isFocusedEmail ? '-active' : ''}`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="email"
                  placeholder="email@example.com"
                  required={true}
                />
              </div>
            </div>

            <div className="form-question">
              <span>Phone number</span>
              <p>電話番号</p>
              {telValidAlert && <span style={{color: "red"}}>半角数字のみご入力ください</span>}
              <div className="input-tels">
                <input
                  className={`input-tel${isFocusedTel ? '-active' : ''}`}
                  value={tel}
                  onChange={(e) => handleChangeTel(e)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="tel"
                  placeholder="09012345678（ハイフンなし）"
                />
              </div>
            </div>

            <div className="form-question">
              <span>Message</span>
              <p>メッセージ</p>
              <div className="input-messages">
                <textarea
                  className={`input-message${isFocusedMessage ? '-active' : ''}`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="message"
                  placeholder="ご自由にご入力ください！"
                />
              </div>
            </div>

            <div className="form-question">
              <span>Food Allergy</span>
              <p style={{fontSize: 12}}>食材アレルギーがございましたら<br />ご入力ください</p>
              <div className="input-allergies">
                <textarea
                  className={`input-allergy${isFocusedAllergy ? '-active' : ''}`}
                  value={allergy}
                  onChange={(e) => setAllergy(e.target.value)}
                  onFocus={(e) => handleFocus(e.target.name)}
                  onBlur={(e) => handleBlur(e.target.name)}
                  name="allergy"
                  placeholder="鶏卵、牛乳、小麦 等"
                />
              </div>
            </div>

            <div className="form-question">
              <span>Other Guests</span>
              <p>お連れ様</p>
              {Array.isArray(otherGuests) && otherGuests.length > 0 && selectedRadioBtnAttendance === "ご出席" ? (
                <OtherGuests
                  otherGuests={otherGuests}
                  handleOtherGuests={handleOtherGuests}
                />
              ) : (
                <></>
              )}
              <button onClick={AddOtherGuest} className="add-guest-Btn" type="button">お連れ様を追加する</button>
            </div>

{/* <button onClick={() => {console.log("selectedRadioBtnAttendance : ", selectedRadioBtnAttendance)}}>確認！</button> */}


            <div className="re-captcha-area">
              <ReCAPTCHA
                ref={refCaptcha}
                onChange={onChangeReCaptcha}
                sitekey={process.env.React_APP_RECAPTCHA_SITE_KEY}
                onErrored={onErrorReCaptcha}
                size="compact"
              />
            </div>
            <button className="submit-Btn" type="submit" value="確認画面へ">確認画面へ</button>
        </form>
      </div>
    </div>
    </>
  )
}

export default Form
