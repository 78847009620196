import { useEffect, useRef, useState } from "react";
import Body from "../Body";
import Footer from "../Footer";
import Header from "../Header";

const Layout = () => {

  // 状態を定義して、初期値を設定
  const [groomName, setGroomName] = useState(""); // 新郎漢字フルネーム
  const [brideName, setBrideName] = useState(""); // 新婦漢字フルネーム
  const [groomFirstName, setGroomFirstName] = useState(""); // 新郎アルファベットfirstName
  const [brideFirstName, setBrideFirstName] = useState(""); // 新婦アルファベットfirstName
  const [messageFromGroom, setMessageFromGroom] = useState(""); // 新郎メッセージ
  const [messageFromBride, setMessageFromBride] = useState(""); // 新婦メッセージ
  const [receptionDateTimeStr, setReceptionDateTimeStr] = useState(""); // 「yyyy-mm-ddTHH:mm:ss」形式で目標を設定する
  const [ceremonyTime, setCeremonyTime] = useState("");
  const [receptionTime, setReceptionTime] = useState("");
  const [hallDetail, setHallDetail] = useState({
    name: "",
    url: "",
    tel: "",
    address: ""
  });


  // const groomName = "" // 新郎漢字フルネーム
  // const brideName = ""; // 新婦漢字フルネーム
  // const groomFirstName = ""; // 新郎アルファベットfirstName
  // const brideFirstName = ""; // 新婦アルファベットfirstName
  // const receptionDateTimeStr = ""; // 「yyyy-mm-ddTHH:mm:ss」形式で目標を設定する
  // const ceremonyTime = "";
  // const receptionTime = "";
  // const hallDetail = {
  //   name: "",
  //   url: "",
  //   tel: "",
  //   address: ""
  // }

  // fetch('/systemDefine.json')
  // .then(response => response.json())
  // .then(data => {
  //   const groomName = data.hostInformation.groomName;

  // })


  // const groomName = "山田 晃生"; // 新郎漢字フルネーム
  // const brideName = "元田 麻里奈"; // 新婦漢字フルネーム
  // const groomFirstName = "Kohki"; // 新郎アルファベットfirstName
  // const brideFirstName = "Marina"; // 新婦アルファベットfirstName
  // const receptionDateTimeStr = "2025-10-18T10:00:00"; // 「yyyy-mm-ddTHH:mm:ss」形式で目標を設定する
  // const ceremonyTime = "10時00分（受付 9時30分）";
  // const receptionTime = "11時00分（受付 10時30分）";
  // const hallDetail = {
  //   name: "RAVI MANA  KOBE",
  //   url: "https://wedding.escrit.jp/place/ravimana/",
  //   tel: "0120-964-825",
  //   address: "兵庫県神戸市中央区神戸空港８−２"
  // }

  // スクロール先のRefを定義
  const memoriesRef = useRef(null);
  const profileRef = useRef(null);
  const messageRef = useRef(null);
  const countdownRef = useRef(null);
  const informationRef = useRef(null);
  // const formRef = useRef(null);
  const refs = {
    memoriesRef,
    profileRef,
    messageRef,
    countdownRef,
    informationRef,
    // formRef
  }

  // 要素を引数に渡したらその位置までスクロールする関数
  const scrollToElement = (ele) => {
    // スクロール先の要素の位置を取得
    const rect = ele.getBoundingClientRect();
    // スクロール先の要素の位置を調整
    const offset = 50;
    const top = rect.top + window.scrollY - offset;
    // スクロールの実行
    window.scrollTo({ top, behavior: 'smooth'});
  }

  // Navメニュークリック時に行う処理
  const handleSelectButton = async(selectButton) => {
    switch(selectButton) {
      case "top":
        window.scrollTo({ top: 0, behavior: 'smooth'});
        break;
      case "memories":
        scrollToElement(memoriesRef.current);
        break;
      case "profile":
        scrollToElement(profileRef.current);
        break;
      case "message":
        scrollToElement(messageRef.current);
        break;
      case "countdown":
        scrollToElement(countdownRef.current);
        break;
      case "information":
        scrollToElement(informationRef.current);
        break;
      // case "form":
      //   scrollToElement(formRef.current);
      //   break;
      default:
        break;
    }
  }


    /// Topアイコンが押された場合
    // selectButton === "top" ? window.scrollTo({ top: 0, behavior: 'smooth'}):
    // if(selectButton === "top"){
    //     window.scrollTo({ top: 0, behavior: 'smooth'});
    // }
    /// トップページでNavが押された場合
    /// スクロールを行う処理を発火
    // selectButton === "memories" ? scrollToElement(memoriesRef.current):
    // selectButton === "profile" ? scrollToElement(profile.current):
    // selectButton === "message" ? scrollToElement(messageRef.current):
    // selectButton === "countdown" ? scrollToElement(countdownRef.current):
    // selectButton === "information" ? scrollToElement(informationRef.current):
    // selectButton === "form" ? scrollToElement(formRef.current):

  //   switch(selectButton) {
  //     case "memories":
  //         scrollToElement(memoriesRef.current);
  //         break;
  //     case "profile":
  //         scrollToElement(profile.current);
  //         break;
  //     case "message":
  //         scrollToElement(messageRef.current);
  //         break;
  //     case "countdown":
  //         scrollToElement(countdownRef.current);
  //         break;
  //     case "information":
  //         scrollToElement(informationRef.current);
  //         break;
  //     case "form":
  //         scrollToElement(formRef.current);
  //         break;
  //     default:
  //         // デフォルトの処理（何もしない場合も明示的に）
  //         break;
  // }


    // if(selectButton === "memories"){
    //     scrollToElement(memoriesRef.current);
    // }
    // if(selectButton === "profile"){
    //     scrollToElement(profile.current);
    // }
    // if(selectButton === "message"){
    //     scrollToElement(messageRef.current);
    // }
  // }

    // データを取得するための useEffect
    useEffect(() => {
      fetch('/systemDefine.json')
        .then(response => response.json())
        .then(data => {
          // 取得したデータを状態に設定
          setGroomName(data.hostInformation.groomName);
          setBrideName(data.hostInformation.brideName);
          setGroomFirstName(data.hostInformation.groomFirstName);
          setBrideFirstName(data.hostInformation.brideFirstName);
          setMessageFromGroom(data.hostInformation.messageFromGroom);
          setMessageFromBride(data.hostInformation.messageFromBride);
          setReceptionDateTimeStr(data.receptionInformation.receptionDateTimeStr);
          setCeremonyTime(data.receptionInformation.ceremonyTime);
          setReceptionTime(data.receptionInformation.receptionTime);
          setHallDetail(data.receptionInformation.hallDetail);
          console.log("data" , data);
        })
        .catch(error => console.error("Error loading JSON:", error));
    }, []); // コンポーネントがマウントされたときに一度だけ実行

  return (
    <div style={{ maxWidth: "700px", margin: "0 auto", backgroundColor: "rgb(200, 230, 255)" }}>
      <Header
        handleSelectButton={handleSelectButton}
      />
      <Body
        groomName={groomName || "未設定"}
        brideName={brideName || "未設定"}
        groomFirstName={groomFirstName || "未設定"}
        brideFirstName={brideFirstName || "未設定"}
        messageFromGroom={messageFromGroom || "未設定"}
        messageFromBride={messageFromBride || "未設定"}
        receptionDateTimeStr={receptionDateTimeStr || "未設定"}
        ceremonyTime={ceremonyTime || "未設定"}
        receptionTime={receptionTime || "未設定"}
        hallDetail={hallDetail || "未設定"}
        // Refをオブジェクトとして渡す
        refs={refs}
      />
      <Footer
        groomFirstName={groomFirstName || "未設定"}
        brideFirstName={brideFirstName || "未設定"}
      />
    </div>
  )
}

export default Layout
