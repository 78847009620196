import React, { useEffect, useState } from 'react';
import '../Body.css';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useLocation } from 'react-router-dom';


const Map = ({ hallDetail }) => {

    // GoogleMapコンポーネントのcenterプロパティを直接定数で定義すると、そこから中心を移動できなくなる。
    // 状態変数で持たせることによって、なぜか地図が初期中心箇所に戻らなくなる。
    // eslint-disable-next-line
    // const [selected, setSelected] = useState(null);
    // const url = hallDetail.url;
    // const name = hallDetail.name;

    const pageLocation = useLocation();
    const googleMapPlaceId = hallDetail.googleMapPlaceId;

    const mapStyles = {
        height: "100%",
        width: "100%"
    };

    console.log("hallDetail : ", hallDetail);

    // 初期表示時のマーカー位置
    const defaultLocations = {
        name: hallDetail.name,
        url: hallDetail.url,
        location: {
            lat: hallDetail.location?.lat,
            lng: hallDetail.location?.lng
        }
        // location: {
        //     lat: 34.63539629,
        //     lng: 135.21184693
        // }
    };

    // const [center, setCenter] = useState({ lat: 34.7030, lng: 135.4917 });
    // const [center, setCenter] = useState(defaultLocations.location);

    // // マーカー選択時の処理
    // const handleMarkerClick = () => {
    //     setSelected(location);
    // };
    // // InfoWindowのリンククリック時処理
    // const handleClickButton = () => {
    //     console.log("url : ", url);
    //     window.open(url);
    // }

    const [location, setLocation] = useState(null);

    const handleButtonClick = () => {
        const url = `https://www.google.com/maps/place/?q=${encodeURIComponent(hallDetail.name)}`;
        // window.open(url);
        window.location.href = url;
    };

    useEffect(() => {
        if (window.google && window.google.maps) {
            // 地図の初期化後に、Google Places APIを使って場所を検索
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            // ラヴィマーナ神戸のプレイスIDを使って場所情報を取得
            // const placeId = "ChIJ7y7mpqI7AWAR8zPZLvjwKhw"; // 例: ラヴィマーナ神戸のプレイスID
            const placeId = googleMapPlaceId;
            service.getDetails({ placeId }, (place, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // 取得した場所の位置情報をセット
                    setLocation({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    });
                }
            });
        } // eslint-disable-next-line
    }, [pageLocation.pathname]);


    return (
        <LoadScript
            googleMapsApiKey={process.env.React_APP_GoogleMap_API_KEY}
            libraries={["places"]}
            loadingElement={<div>Loading...</div>}  // 非同期ロード中に表示される要素
        >
            <GoogleMap
                key={`${defaultLocations.location.lat}-${defaultLocations.location.lng}`}
                mapContainerStyle={mapStyles}
                zoom={14}
                center={defaultLocations.location}
                options={{
                    mapTypeControl: false,
                    streetViewControl: false,
                    gestureHandling: 'auto', // ジェスチャー操作を許可
                    draggable: true, // ドラッグによる移動を許可
                }}
            >
            <Marker
                // position={defaultLocations.location}
                position={location}
                // onClick={handleMarkerClick}
            />


            {/* 地図上にボタンを配置 */}
            <div style={{
                position: 'absolute',
                bottom: '25px',
                left: '10px',
                zIndex: 1
            }}>
                <button onClick={handleButtonClick} style={{
                    padding: '5px 8px',
                    backgroundColor: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#4285F4',
                    border: '1px solid #4285F4',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    Googleマップで見る
                </button>
            </div>


            {/* {selected && (
                <InfoWindow
                    position={selected.location}
                    onCloseClick={() => setSelected(null)}
                    closeButtonOptions={{
                        // カスタムスタイルを適用
                        // style: {
                        //     padding: "0px", // パディングを追加してサイズを調整
                        //     height: 10,
                        //     cursor: "pointer", // カーソルをポインターに変更
                        // }
                    }}
                >
                    <div className="map-infoWindow">
                        <button onClick={handleClickButton}>{name}</button>
                    </div>
                </InfoWindow>
            )} */}
            </GoogleMap>
        </LoadScript>
    )
}

export default Map
