// import { useState } from "react"
// import { useCountDownInterval } from "./useCountDownInterval";

import { useState } from "react";
import { useCountDownInterval } from "./useCountDownInterval";

const Countdown = ({ receptionDateTime }) => {

  // 現在の日時
  const now = new Date();

  // 目標までの差分（ミリ秒単位）
  const diffMs = receptionDateTime - now;

  // 目標までの残り時間（日数、時間、分、秒に変換）
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  const [countTime, setCountTime] = useState(diffMs);
  useCountDownInterval(countTime, setCountTime);

  return (
    <div className="countdown-card">
      <h1>Countdown</h1>
      <div className="countdown-area">
        <div className="countdown-date">
          <p>{diffDays}</p>
          <span>DAYS</span>
        </div>
        <div className="countdown-times">
          <div className="countdown-time">
            <p>{diffHours}</p>
            <span>HOURS</span>
          </div>
          <div className="countdown-time">
            <p>{diffMinutes}</p>
            <span>MINUTES</span>
          </div>
          <div className="countdown-time">
            <p>{diffSeconds}</p>
            <span>SECONDS</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Countdown
