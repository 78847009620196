
import InvitationImg from '../img/WeddingInvitation_black.png'

import Slideshow from './body/Slideshow';
import Profile from './body/Profile';
import Message from './body/Message';
import Countdown from './body/Countdown';
import Information from './body/Information';
// import Form from './body/Form';

import './Body.css';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useState } from 'react';
// import { useCountDownInterval } from './body/useCountDownInterval';

// forwardRefで複数のrefを渡す場合は、propsに各refで作成したrefsオブジェクトを渡し、第二引数にrefを渡すことによって、第一引数で渡したrefsオブジェクトの各プロパティにアクセスできる。
const Body = forwardRef(({groomName, brideName, groomFirstName, brideFirstName, messageFromGroom, messageFromBride, receptionDateTimeStr, ceremonyTime, receptionTime, hallDetail, refs},
                ref) =>
              {

  // 目標日程をDate型に変換
  const receptionDateTime = new Date(receptionDateTimeStr);
  // 目標日程を「yyyy年mm月dd日」形式に変換
  const year = receptionDateTime.getFullYear();
  const month = receptionDateTime.getMonth() + 1; // 月は0から始まるので+1
  const day = receptionDateTime.getDate();
  const daysOfWeek = ["(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)"];
  const dayOfWeek = daysOfWeek[receptionDateTime.getDay()];
  const receptionDateStr = `${year}年 ${month}月 ${day}日 ${dayOfWeek}`;

  const navigate = useNavigate();
  const onClickToForm = () => {
    navigate('/form',{
      state: {
        fromPage: ""
      }
    });
  }

  return (
    <div className='body'>
      <div className='invitation-title'>
        <img src={InvitationImg} alt='WeddingInvitation' className='invitation-image' />
        <h1 className='couple-name'>{groomFirstName} <span>&nbsp;&nbsp;&&nbsp;&nbsp;</span> {brideFirstName}</h1>
      </div>
      <div id="memories" ref={refs?.memoriesRef}>
        <Slideshow />
      </div>
      <div id="profile" ref={refs?.profileRef}>
        <Profile
          groomName={groomName}
          brideName={brideName}
          messageFromGroom={messageFromGroom}
          messageFromBride={messageFromBride}
        />
      </div>
      <div id="message" ref={refs?.messageRef}>
        <Message />
      </div>
      <div id="countdown" ref={refs?.countdownRef}>
        <Countdown receptionDateTime={receptionDateTime} />
      </div>
      <div id="information" ref={refs?.informationRef}>
        <Information
          receptionDateStr={receptionDateStr}
          ceremonyTime={ceremonyTime}
          receptionTime={receptionTime}
          hallDetail={hallDetail}
        />
      </div>
      {/* <div id="form" ref={refs?.formRef}>
        <Form />
      </div> */}
      <button onClick={onClickToForm} className='to-form-Btn'>入力画面 へ</button>
    </div>
  )
});
    export default Body;
