
const RadioButton = ({selectedRadioBtn, handleRadioBtn, radioButtons}) => {
    return (
        <div className="radio-buttons">
        {radioButtons.map((option) => (
            <label key={option.jpn} className="radio-button">
                <input
                    className="radio-input"
                    type="radio"
                    value={option.jpn}
                    checked={selectedRadioBtn === option.jpn}
                    onChange={handleRadioBtn}
                    required={true}
                />
                <div className="radio-button-labels">
                    <p>
                        <span>{option.eng}</span><br />
                        {option.jpn}
                    </p>
                </div>
            </label>
        ))}
        </div>
    )
}

export default RadioButton
