import { useEffect } from 'react';

const useCountDownInterval = (countTime, setCountTime) => {
    useEffect(() => {
        const countDownInterval = setInterval(() => {
            if (countTime === 0) {
                // 残り時間が0になった場合、setIntervalの返り値を引数に入れてタイマーを止める
                clearInterval(countDownInterval);
            }
            if (countTime && countTime > 0) {
                // 残り時間が0ではないかつ正の場合、1秒減らした時間でcountTimeをセットする
                setCountTime(countTime - 1);
            }
        }, 1000); // 1000ms毎にsetIntervalを実行する
        return () => {
            clearInterval(countDownInterval);
        };// eslint-disable-next-line
    }, [countTime]);
};

export { useCountDownInterval };