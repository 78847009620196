import { useState } from "react";



const OtherGuests = ({ otherGuests, handleOtherGuests }) => {

    const [isFocusedGuestLastName, setIsFocusedGuestLastName] = useState(false);
    const [isFocusedGuestFirstName, setIsFocusedGuestFirstName] = useState(false);
    const [isFocusedGuestLastNameKana, setIsFocusedGuestLastNameKana] = useState(false);
    const [isFocusedGuestFirstNameKana, setIsFocusedGuestFirstNameKana] = useState(false);

    // 入力欄での入力時・離脱時の処理
    const handleFocus = (name) => {
        switch(name){
            case 'guestLastName':
                setIsFocusedGuestLastName(true);
                break;
            case 'guestFirstName':
                setIsFocusedGuestFirstName(true);
                break;
            case 'guestLastNameKana':
                setIsFocusedGuestLastNameKana(true);
                break;
            case 'guestFirstNameKana':
                setIsFocusedGuestFirstNameKana(true);
                break;
            default:
                return;
        }
    }
    const handleBlur = (name) => {
        switch(name){
            case 'guestLastName':
                console.log("guestLastName ::: test");
                setIsFocusedGuestLastName(false);
                break;
            case 'guestFirstName':
                console.log("guestFirstName ::: test");
                setIsFocusedGuestFirstName(false);
                break;
            case 'guestLastNameKana':
                console.log("guestLastNameKana ::: test");
                setIsFocusedGuestLastNameKana(false);
                break;
            case 'guestFirstNameKana':
                console.log("guestFirstNameKana ::: test");
                setIsFocusedGuestFirstNameKana(false);
                break;
            default:
                return;
        }
    }

    // フォーム欄の変更時の処理
    const handleChangeOtherGuests = async(name, value, index) => {
        const newOtherGuests = [...otherGuests];
        newOtherGuests[index][name] = value;
        await handleOtherGuests(newOtherGuests);
    }

    // お連れ様欄削除時の処理
    const handleDeleteOtherGuest = (index) => {
        const newOtherGuests = [...otherGuests];
        newOtherGuests.splice(index, 1);
        handleOtherGuests(newOtherGuests);
    }


    return (
    <div>
        {otherGuests.map((otherGuest, index) => (
            <div className="otherGuest-area" key={index}>
                <p>お連れ様（{index + 1}人目）</p>
                <span>Guest Name</span>
                <p>お名前</p>
                <div className="input-names">
                    <input
                        className={`input-name${isFocusedGuestLastName ? '-active' : ''}`}
                        value={otherGuest.guestLastName}
                        onChange={(e) => handleChangeOtherGuests(e.target.name, e.target.value, index)}
                        onFocus={(e) => handleFocus(e.target.name)}
                        onBlur={(e) => handleBlur(e.target.name)}
                        name="guestLastName"
                        placeholder="姓"
                        required={true}
                    />
                    <input
                        className={`input-name${isFocusedGuestFirstName ? '-active' : ''}`}
                        value={otherGuest.guestFirstName}
                        onChange={(e) => handleChangeOtherGuests(e.target.name, e.target.value, index)}
                        onFocus={(e) => handleFocus(e.target.name)}
                        onBlur={(e) => handleBlur(e.target.name)}
                        name="guestFirstName"
                        placeholder="名"
                        required={true}
                    />
                </div>

                <span>Guest Name(Kana)</span>
                <p>お名前(ふりがな)</p>
                <div className="input-names">
                    <input
                        className={`input-name${isFocusedGuestLastNameKana ? '-active' : ''}`}
                        value={otherGuest.guestLastNameKana}
                        onChange={(e) => handleChangeOtherGuests(e.target.name, e.target.value, index)}
                        onFocus={(e) => handleFocus(e.target.name)}
                        onBlur={(e) => handleBlur(e.target.name)}
                        name="guestLastNameKana"
                        placeholder="やまだ"
                        required={true}
                    />
                    <input
                        className={`input-name${isFocusedGuestFirstNameKana ? '-active' : ''}`}
                        value={otherGuest.guestFirstNameKana}
                        onChange={(e) => handleChangeOtherGuests(e.target.name, e.target.value, index)}
                        onFocus={(e) => handleFocus(e.target.name)}
                        onBlur={(e) => handleBlur(e.target.name)}
                        name="guestFirstNameKana"
                        placeholder="はなこ"
                        required={true}
                    />
                </div>
                <button
                    onClick={() => handleDeleteOtherGuest(index)}
                    className="otherGuest-delete-Btn"
                    type="button"
                >削除する</button>
            </div>
        ))}
    </div>
    )
}

export default OtherGuests
