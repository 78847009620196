
import GroomIcon from '../../img/GroomIcon.png';
import BrideIcon from '../../img/BrideIcon.png';

const Profile = ({ groomName, brideName, messageFromGroom, messageFromBride }) => {
  return (
    <div className="profile-card">
        <h1>HOST</h1>
        <div className='profile-area'>
          <h2>Groom</h2>
          <div className='profile-icon'>
            <img src={GroomIcon} alt='Groom_img' className='profile-icon-image' />
          </div>
          {/* Real Message */}
          <div className='profile-text'>
            <h3>{groomName}</h3>
            <div dangerouslySetInnerHTML={{ __html: messageFromGroom }} />
            {/* <p>1992年8月22日 京都生まれのO型</p>
            <p>MBTIは エンターテイナー（ESFP）</p>
            <br />
            <p>趣味はキャンプ・サウナ・ロードバイク</p>
            <p>あと、、、お酒大好きです🍺</p>
            <p>美味しい料理を用意してお待ちしています！</p> */}
          </div>
          {/* Test Message */}
          {/* <div className='profile-text'>
            <p>{groomName}</p>
            <p>1992年8月22日 京都生まれのO型</p>
            <p>MBTIは エンターテイナー（ESFP）</p>
            <br />
            <p>趣味はキャンプ・サウナ・ロードバイク</p>
            <p>あと、、、お酒大好きです🍺</p>
            <p>美味しい料理を用意してお待ちしています！</p>
          </div> */}
        </div>
        <div className='profile-area'>
          <h2>Bride</h2>
          <div className='profile-icon'>
            <img src={BrideIcon} alt='Bride_img' className='profile-icon-image' />
          </div>
          {/* Real Message */}
          {/* <div className='profile-text'>
            <p>{brideName}</p>
            <p>1995年1月1日 兵庫生まれのA型</p>
            <p>MBTIは 主人公（ENFJ）</p>
            <br />
            <p>趣味は美味しいご飯を食べること</p>
            <p>皆様にお会いできることを</p>
            <p>楽しみにしています！</p>
          </div> */}
          {/* Test Message */}
          <div className='profile-text'>
            <h3>{brideName}</h3>
            <div dangerouslySetInnerHTML={{ __html: messageFromBride }} />
            {/* <p>1995年1月1日 兵庫生まれのA型</p>
            <p>MBTIは 主人公（ENFJ）</p>
            <br />
            <p>趣味は美味しいご飯を食べること</p>
            <p>皆様にお会いできることを</p>
            <p>楽しみにしています！</p> */}
          </div>
        </div>
    </div>
  )
}

export default Profile
