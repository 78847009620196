
const FormCompleted = () => {

    return(
        <>
            <div>
                <h1>送信ありがとうございました。</h1>
            </div>
        </>
    )


}
export default FormCompleted;