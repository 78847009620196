import { useState } from 'react';
import './Header.css';
import ToggleButton from './Header/ToggleButton';
import Navigation from './Header/Navigation';

const Header = (props) => {
  // props受け取り
  const handleSelectButton = (selectButton) => {
    props.handleSelectButton(selectButton);
  }

  const [open, setOpen] = useState(false);

  const toggleFunction = () => {
      setOpen((prevState) => !prevState);
  }


  return (
    <div className='header'>
      <ToggleButton
          open={open}
          controls="navigation"
          label="メニューを開きます"
          onClick={toggleFunction}
      />
      <Navigation
          id="navigation"
          open={open}
          onClick={toggleFunction}
          handleSelectButton={handleSelectButton}
      />
    </div>
  )
}

export default Header;
