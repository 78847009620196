
import './../Header.css';

const Navigation = (props) => {
    const id = props.id;
    const open = props.open;

    const handleNavClick = (selectButton) => {
        props.onClick();
        props.handleSelectButton(selectButton);

    }

    return(
        <nav
            id={id}
            aria-hidden={!open}
            className="navigation"
        >
            <ul>
                <li className='nav'>
                    <button onClick={() => handleNavClick("top")}>Top</button>
                </li>
                {/* <li className='nav'>
                    <button onClick={() => handleNavClick("memories")}>Memories</button>
                </li> */}
                <li className='nav'>
                    <button onClick={() => handleNavClick("profile")}>Host</button>
                </li>
                <li className='nav'>
                    <button onClick={() => handleNavClick("message")}>Message</button>
                </li>
                <li className='nav'>
                    <button onClick={() => handleNavClick("countdown")}>Countdown</button>
                </li>
                <li className='nav'>
                    <button onClick={() => handleNavClick("information")}>Information</button>
                </li>
                {/* <li className='nav'>
                    <button onClick={() => handleNavClick("form")}>Invitation</button>
                </li> */}
            </ul>
        </nav>
    )
}

export default Navigation;