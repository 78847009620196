
import { Autoplay, EffectFade, Zoom } from 'swiper/modules';
import slide_img_1 from '../../img/slide_1.png';
import slide_img_2 from '../../img/slide_2.png';
import slide_img_3 from '../../img/slide_3.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';


const Slideshow = () => {


    return (
        <>
            {/* <div className='memories-card'>
                <h1>Memories</h1>
            </div> */}
            <Swiper
                modules={[Autoplay, EffectFade, Zoom]}
                className="swiper-container"
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                speed={2000} // スライドのアニメーション速度を設定
                effect="fade" // スライドの遷移エフェクトを設定（fade、cubeなど）
                zoom={{
                    maxRatio: 2,
                }}
            >
                <SwiperSlide>
                    <div className='slide-zoom-container'>
                        <img src={slide_img_1} alt='slide_img_1' className='slide-img' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide-zoom-container'>
                        <img src={slide_img_2} alt='slide_img_2' className='slide-img' />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide-zoom-container'>
                        <img src={slide_img_3} alt='slide_img_3' className='slide-img' />
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

export default Slideshow
