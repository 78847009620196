import { useLocation, useNavigate } from "react-router-dom";
import "./FormConfirm.css";

const FormConfirm = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const {
        firstName,
        lastName,
        firstNameKana,
        lastNameKana,
        address1,
        address2,
        email,
        tel,
        message,
        allergy,
        otherGuests,
        selectedRadioBtnAttendance,
        selectedRadioBtnGuest
    } = location.state || ""; // stateが存在しない場合のためのデフォルト値

    const handleBack = () => {
        navigate("/form",
            { state:
                {
                    firstName,
                    lastName,
                    firstNameKana,
                    lastNameKana,
                    address1,
                    address2,
                    email,
                    tel,
                    message,
                    allergy,
                    otherGuests,
                    selectedRadioBtnAttendance,
                    selectedRadioBtnGuest,
                    fromPage: "confirm"
                }
            }
        );
    }

    const handleSubmit = () => {
        navigate('/form/completed');
    }

    return (
        <div className="form-confirm-body">
            <div className="form-confirm-card">
                <h3>入力内容の確認をお願いします。</h3>

                <p className="form-confirm-question">ご出欠</p>
                <p className="form-confirm-answer">{selectedRadioBtnAttendance}・{selectedRadioBtnGuest}</p>

                <p className="form-confirm-question">お名前</p>
                <p className="form-confirm-answer">{lastName} {firstName}</p>

                <p className="form-confirm-question">お名前（かな）</p>
                <p className="form-confirm-answer">{lastNameKana} {firstNameKana}</p>

                <p className="form-confirm-question">住所</p>
                <p className="form-confirm-answer">{address1}</p>
                <p className="form-confirm-answer">{address2}</p>

                <p className="form-confirm-question">Email</p>
                <p className="form-confirm-answer">{email}</p>

                <p className="form-confirm-question">Telephone</p>
                <p className="form-confirm-answer">{tel}</p>

                <p className="form-confirm-question">Message</p>
                <p className="form-confirm-answer">{message}</p>

                <p className="form-confirm-question">Allergy</p>
                <p className="form-confirm-answer">{allergy}</p>

                <p className="form-confirm-question">Other Guests</p>
                <p className="form-confirm-answer">{otherGuests}</p>

                <button onClick={handleBack}>修正する</button>
                <button onClick={handleSubmit}>送信</button>
            </div>
        </div>
    )
}

export default FormConfirm;