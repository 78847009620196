
import './../Header.css';

const ToggleButton = (props) => {
    // propsから受け取る
    const open = props.open;
    const controls = props.controls;
    const label = props.label;
    const onClick = props.onClick;

    return(
        <button
            className="toggleButton"
            type="button"
            aria-controls={controls}
            aria-expanded={open}
            aria-label={label}
            onClick={onClick}
        >
            <span className="line-1"></span>
            <span className="line-2"></span>
            <span className="line-3"></span>
        </button>
    )
}

export default ToggleButton;