
const Message = () => {

  return (
    <div className="message-card">
        <h1>Message</h1>
        <div className='message-area'>
          <p>謹啓</p>
          <p>皆様におかれましては<br />ますますご清祥のこととお慶び申し上げます</p>
          <p>このたび、私たちは結婚をすることになりました<br />つきましては、日頃お世話になっている皆様に<br />感謝を込めて、ささやかな小宴を催したく存じます</p>
          <p>ご多用中、誠に恐縮ではございますが<br />ぜひご出席をいただきたくご案内申し上げます</p>
          <p>謹白</p>
        </div>
    </div>
  )
}

export default Message
