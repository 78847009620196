import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/body/Layout';
import Form from './components/Form';
import FormConfirm from './components/FormConfirm.js';
import FormCompleted from './components/FormCompleted.js';


function App() {
  return (
    <div id='app'>
      <Router>
        <Routes>
          <Route path='/' element={<Layout />} />
          <Route path='/form' element={<Form />} />
          <Route path='/form/confirm' element={<FormConfirm />} />
          <Route path='/form/completed' element={<FormCompleted />} />
        </Routes>

      </Router>
      {/* <Layout  /> */}
    </div>
  );
}

export default App;
