
import './Footer.css';

const Footer = ( {groomFirstName, brideFirstName} ) => {
  return (
    <div className="footer-area">
      <p>{groomFirstName} &nbsp; & &nbsp; {brideFirstName}'s &nbsp; ~ Wedding ~</p>
    </div>
  )
}

export default Footer;
